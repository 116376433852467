@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-family: "Clash Display", sans-serif;
}

p,
button,
a,
input {
  font-family: "Satoshi", sans-serif;
}

.content h3,
.content h2,
.content h1,
.content h4,
.content h5,
.content h6 {
  font-weight: 600;
  margin-top: 30px;
  font-size: larger;
}
.content a {
  text-decoration: underline;
}
